import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Card,
	Divider,
	List,
	ListItemButton,
	ListItemText,
	TablePagination,
} from "@mui/material";
import {SearchForm} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {Tenant, TenantQuery, useTenantApi} from "../api";
import {FullScreenMessage} from "../controls";
import {useLocalization} from "../i18n";
import {LoginState} from "../LoginState";
import {useUser} from "../user";
import {View, ViewHeader} from "./View";

export function TenantSelection() {
	const {t} = useLocalization();
	const {queryTenants} = useTenantApi();
	const navigate = useNavigate();
	const {superUser} = useUser();

	const [query, setQuery] = useState<TenantQuery>({size: 10});
	const {loading, error, result} = useAsync(queryTenants, [query]);

	const [initialLoading, setInitialLoading] = useState(true);

	useEffect(() => {
		if (!loading) {
			setInitialLoading(false);
		}
	}, [loading]);

	const [tenants, setTenants] = useState<Tenant[]>([]);
	useEffect(() => {
		if (result) {
			setTenants(result?.content ?? []);
		}
	}, [result]);

	// Automatically navigate to tenant, if there is only one and search is not active
	useEffect(() => {
		if (result && !query.search) {
			if (result.content?.length == 1) {
				navigate(`/${result.content[0].id}`);
			}
		}
	}, [result, query]);

	if (!query.search && result?.totalElements === 0) {
		return (
			<FullScreenMessage>
				<Alert severity="error">
					<AlertTitle>{t("tenants.selection.noTenants.title")}</AlertTitle>
					{t("tenants.selection.noTenants.description")}
				</Alert>
			</FullScreenMessage>
		);
	}

	return (
		<LoginState loading={initialLoading} error={error}>
			<Box py={4}>
				<View maxWidth="md">
					<ViewHeader
						title={t("tenants.selection.title")}
						actions={superUser && (
							<Button variant="outlined" color="primary" component={Link} to="new">
								{t("tenants.create.title")}
							</Button>
						)}
					/>
					<SearchForm
						label={t("filter.search")}
						fullWidth
						onSearch={search => setQuery({...query, search, page: 0})}
					/>
					<Card>
						<List>
							{tenants.map((tenant) => (
								<ListItemButton
									key={tenant.id}
									component={Link}
									to={`/${tenant.id}`}
									disabled={loading}
								>
									<ListItemText primary={tenant.name} />
								</ListItemButton>
							))}
						</List>
						<Divider />
						<TablePagination
							component="div"
							rowsPerPageOptions={[10]}
							count={result?.totalElements ?? 0}
							rowsPerPage={10}
							page={result?.number ?? 0}
							onPageChange={(_, page) => setQuery({...query, page})}
							onRowsPerPageChange={() => void 0}
						/>
					</Card>
				</View>
			</Box>
		</LoginState>
	);
}
