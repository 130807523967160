import {Button} from "@mui/material";
import {
	DataTable,
	DataTableCellProps,
	useDataTableStorage,
	useSpringPage,
	useSpringPageable,
	useStorage,
} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {Compartment, useCompartmentApi} from "../../api";
import {EmptyList} from "../../controls";
import {useLocalization} from "../../i18n";
import {useAnyPermission} from "../../tenant";
import {LocationLinkChip} from "../location/LocationChip";
import {View, ViewHeader} from "../View";
import {CompartmentCell} from "./CompartmentCell";
import {CompartmentFilter} from "./CompartmentFilter";
import {CompartmentStatusChip} from "./CompartmentStatusChip";
import {LockStatusChip} from "./LockStatusChip";

export function CompartmentList() {
	const {t} = useLocalization();

	const {queryCompartments} = useCompartmentApi();

	const canCreate = useAnyPermission("COMPARTMENT_CREATE");

	const columns = useMemo(() => [
		{
			label: t("compartments.number"),
			field: "number",
			sortable: true,
			default: true,
			component: CompartmentCell,
		},
		{
			label: t("compartments.size"),
			field: "size",
			sortable: true,
			default: true,
		},
		{
			label: t("compartments.location"),
			field: "location.name",
			sortable: true,
			default: true,
			component: ({row}: DataTableCellProps<Compartment>) => <LocationLinkChip location={row.location} />,
		},
		{
			label: t("compartments.status.title"),
			field: "booked",
			sortable: false,
			default: true,
			component: ({row}: DataTableCellProps<Compartment>) => <CompartmentStatusChip booked={row.booked} />,
		},
		{
			label: t("compartments.lock.status"),
			field: "lock.status",
			sortable: false,
			default: true,
			component: ({row}: DataTableCellProps<Compartment>) =>
				row.lock?.status ? <LockStatusChip status={row.lock.status} /> : null,
		},
	], [t]);

	const {onPageChange, onSort, ...storage} = useDataTableStorage("CompartmentList.paging");
	const pageable = useSpringPageable(storage);

	const [filter, setFilter] = useStorage<CompartmentFilter>("CompartmentList.filter", {});

	const query = useMemo(() => ({
		...filter,
		...pageable,
	}), [filter, pageable]);

	const {loading, error, result} = useAsync(queryCompartments, [query]);
	const {rows, page} = useSpringPage(result);

	return (
		<View>
			<ViewHeader
				title={t("compartments.title")}
				actions={canCreate && (
					<Button color="primary" variant="contained" component={Link} to="new">
						{t("compartments.create.title")}
					</Button>
				)}
			/>
			<CompartmentFilter value={filter} onChange={setFilter} />
			<DataTable
				columns={columns}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				onSort={onSort}
				onPageChange={onPageChange}
				empty={<EmptyList />}
				{...storage}
			/>
		</View>
	);
}
