import {Box, Card, List, ListItemButton, ListItemIcon, ListItemText, Skeleton, Typography} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {Gauge} from "@mui/x-charts";
import {Duration, DurationFormat, ErrorAlert} from "@variocube/app-ui";
import React, {ReactNode} from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {useBookingApi, useKpiApi} from "../../api";
import {BoxNumber} from "../../controls";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../../tenant";
import {formatLocationPath} from "../location/path";
import {View, ViewHeader} from "../View";

export function Dashboard() {
	const {t} = useLocalization();
	const {
		getTotalBookings,
		getTotalCompartments,
		getTotalUsers,
		getAverageBookingDuration,
		getUtilization,
	} = useKpiApi();
	const {queryBookings} = useBookingApi();
	const tenantId = useTenantId();

	const totalBookings = useAsync(getTotalBookings, []);
	const totalCompartments = useAsync(getTotalCompartments, []);
	const totalUsers = useAsync(getTotalUsers, []);
	const averageBookingDuration = useAsync(getAverageBookingDuration, []);
	const utilization = useAsync(getUtilization, []);
	const bookings = useAsync(() => queryBookings({size: 5, sort: ["id,desc"]}), []);

	return (
		<View>
			<ViewHeader title={t("dashboard.title")} />
			<Box>
				<Grid container spacing={2} alignContent="stretch">
					<Grid item xs={6} sm={4} md={3}>
						<NumberKpiCard label={t("dashboard.totalCompartments")} {...totalCompartments} />
					</Grid>
					<Grid item xs={6} sm={4} md={3}>
						<NumberKpiCard label={t("dashboard.totalUsers")} {...totalUsers} />
					</Grid>
					<Grid item xs={6} sm={4} md={3}>
						<NumberKpiCard label={t("dashboard.totalBookings")} {...totalBookings} />
					</Grid>
					<Grid item xs={6} sm={4} md={3}>
						<DurationKpiCard label={t("dashboard.averageBookingDuration")} {...averageBookingDuration} />
					</Grid>
					<Grid item xs={12} md={6}>
						<Card sx={{pt: 1, pb: 5, px: 2}}>
							<Typography variant="overline">{t("dashboard.utilization")}</Typography>
							<Gauge
								value={utilization.result?.booked}
								valueMax={utilization.result?.total ?? 0}
								startAngle={-110}
								endAngle={110}
								height={220}
								text={({value, valueMax}) => `${value} / ${valueMax}`}
							/>
						</Card>
					</Grid>
					<Grid item xs={12} md={6}>
						<Card>
							<Box sx={{pt: 1, pb: 0, px: 2}}>
								<Typography variant="overline">{t("dashboard.latestBookings")}</Typography>
							</Box>
							<List>
								{bookings.result?.content?.map(booking => (
									<ListItemButton
										component={Link}
										to={`/${tenantId}/bookings/${booking.id}`}
										key={booking.id}
									>
										<ListItemIcon>
											<BoxNumber>{booking.compartment.number}</BoxNumber>
										</ListItemIcon>
										<ListItemText
											primary={booking.users.map(user => user.displayName).join(", ")}
											secondary={formatLocationPath(booking.location)}
										/>
									</ListItemButton>
								))}
							</List>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</View>
	);
}

interface KpiCardProps<T> {
	label: string;
	loading: boolean;
	error: any;
	result?: T;
}

function KpiCard({label, loading, error, result}: KpiCardProps<ReactNode>) {
	return (
		<Card sx={{px: 2, py: 1, height: "100%"}}>
			<Typography variant="overline">{label}</Typography>
			<Typography variant="h2" component="div">
				{loading && <Skeleton />}
				{error && <ErrorAlert error={error} />}
				{result}
			</Typography>
		</Card>
	);
}

function NumberKpiCard(props: KpiCardProps<number>) {
	return <KpiCard {...props} />;
}

function DurationKpiCard({result, ...props}: KpiCardProps<string>) {
	const duration = result && Duration.from(result);
	return <KpiCard {...props} result={duration ? <DurationFormat value={duration} /> : null} />;
}
