import {IconButton} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Compartment, CompartmentRef} from "../../api";
import {BoxNumber} from "../../controls";
import {useTenantId} from "../../tenant";

interface CompartmentLinkIconButtonProps {
	compartment: Compartment | CompartmentRef;
}

export function CompartmentLinkIconButton({compartment}: CompartmentLinkIconButtonProps) {
	const tenantId = useTenantId();
	return (
		<IconButton component={RouterLink} to={`/${tenantId}/compartments/${compartment.id}`} color="secondary">
			<BoxNumber>{compartment.number}</BoxNumber>
		</IconButton>
	);
}
