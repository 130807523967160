import {Box, Button, Stack} from "@mui/material";
import {
	DataTable,
	DataTableCellProps,
	useDataTableStorage,
	useSpringPage,
	useSpringPageable,
	useStorage,
} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {Location, useLocationApi} from "../../api";
import {EmptyList} from "../../controls";
import {useLocalization} from "../../i18n";
import {useTenantPermission} from "../../tenant";
import {View, ViewHeader} from "../View";
import {LocationLinkChip} from "./LocationChip";
import {LocationFilter} from "./LocationFilter";
import {LocationIcon} from "./LocationIcon";

export function LocationList() {
	const {t} = useLocalization();

	const {queryLocations} = useLocationApi();

	const canEdit = useTenantPermission("LOCATION_EDIT");

	const columns = useMemo(() => [
		{
			label: t("locations.name"),
			field: "name",
			sortable: true,
			default: true,
			component: LocationCell,
		},
		{
			label: t("locations.type"),
			field: "type",
			sortable: true,
			default: true,
			component: LocationTypeCell,
		},
		{
			label: t("locations.parent"),
			field: "parent.name",
			sortable: true,
			default: true,
			component: LocationParentCell,
		},
	], [t]);

	const {onPageChange, onSort, ...storage} = useDataTableStorage("LocationList.paging");
	const pageable = useSpringPageable(storage);

	const [filter, setFilter] = useStorage<LocationFilter>("LocationList.filter", {});

	const query = useMemo(() => ({
		...filter,
		...pageable,
	}), [filter, pageable]);

	const {loading, error, result} = useAsync(queryLocations, [query]);
	const {rows, page} = useSpringPage(result);

	return (
		<View>
			<ViewHeader
				title={t("locations.title")}
				actions={canEdit && (
					<Button variant="contained" color="primary" component={Link} to="manage">
						{t("locations.manage")}
					</Button>
				)}
			/>
			<LocationFilter value={filter} onChange={setFilter} onSearch={search => setFilter({...filter, search})} />
			<DataTable
				columns={columns}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				onSort={onSort}
				onPageChange={onPageChange}
				empty={<EmptyList />}
				{...storage}
			/>
		</View>
	);
}

function LocationCell({row}: DataTableCellProps<Location>) {
	return <LocationLinkChip location={row} ancestry={false} />;
}

function LocationParentCell({row}: DataTableCellProps<Location>) {
	return row.parent ? <LocationLinkChip location={row.parent} /> : null;
}

function LocationTypeCell({row}: DataTableCellProps<Location>) {
	const {t} = useLocalization();

	if (row.type) {
		return (
			<Stack direction="row" spacing={1} alignItems="center">
				<LocationIcon type={row.type} />
				<Box>{t(`locations.types.${row.type}`)}</Box>
			</Stack>
		);
	}
	else {
		return null;
	}
}
