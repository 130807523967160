import {Button, Typography} from "@mui/material";
import {
	DataTable,
	DataTableCellProps,
	useDataTableStorage,
	useSpringPage,
	useSpringPageable,
	useStorage,
} from "@variocube/app-ui";
import React, {Fragment, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {TenantUser, useTenantUserApi} from "../../api";
import {EmptyList} from "../../controls";
import {TFunc, useLocalization} from "../../i18n";
import {GroupLinkChip} from "../group/GroupChip";
import {View, ViewHeader} from "../View";
import {UserLinkChip} from "./UserChip";
import {UserFilter} from "./UserFilter";

export function UserList() {
	const {t} = useLocalization();
	const {queryUsers} = useTenantUserApi();
	const columns = useMemo(() => createColumns(t), [t]);
	const {onPageChange, onSort, ...storage} = useDataTableStorage("UserList.paging");
	const pageable = useSpringPageable(storage);
	const [filter, setFilter] = useStorage<UserFilter>("UserList.filter", {});

	const query = useMemo(() => ({
		...filter,
		...pageable,
	}), [filter, pageable]);

	const {loading, error, result} = useAsync(queryUsers, [query]);
	const {rows, page} = useSpringPage(result);

	return (
		<View>
			<ViewHeader
				title={t("users.title")}
				actions={
					<Button variant="contained" component={RouterLink} to="new">
						{t("users.create.title")}
					</Button>
				}
			/>
			<UserFilter value={filter} onChange={setFilter} onSearch={search => setFilter({...filter, search})} />
			<DataTable
				columns={columns}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				onSort={onSort}
				onPageChange={onPageChange}
				empty={<EmptyList />}
				{...storage}
			/>
		</View>
	);
}

function createColumns(t: TFunc) {
	return [
		{
			label: t("users.displayName"),
			field: "displayName",
			sortable: true,
			default: true,
			component: ({row}: DataTableCellProps<TenantUser>) => <UserLinkChip user={row.user} />,
		},
		{
			label: t("users.familyName"),
			field: "user.familyName",
			sortable: true,
			default: true,
			component: ({row}: DataTableCellProps<TenantUser>) => <Typography>{row.user.familyName}</Typography>,
		},
		{
			label: t("users.givenName"),
			field: "user.givenName",
			sortable: true,
			default: true,
			component: ({row}: DataTableCellProps<TenantUser>) => <Typography>{row.user.givenName}</Typography>,
		},
		{
			label: t("users.email"),
			field: "user.email",
			sortable: true,
			default: true,
			component: ({row}: DataTableCellProps<TenantUser>) => <Typography>{row.user.email}</Typography>,
		},
		{
			label: t("groups.title"),
			field: "groups.name",
			sortable: false,
			default: true,
			component: ({row}: DataTableCellProps<TenantUser>) => (
				<Fragment>
					{row.groups.map(group => <GroupLinkChip key={group.id} group={group} />)}
				</Fragment>
			),
		},
	];
}
