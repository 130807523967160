import {Settings} from "@mui/icons-material";
import {List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {AuditIcon} from "@variocube/app-ui";
import React, {ReactElement} from "react";
import {Link as RouterLink, useMatch} from "react-router-dom";
import {useLocalization} from "../i18n";
import {useTenantId, useTenantPermission, useTenantPermissions} from "../tenant";
import {BookingIcon, CompartmentIcon, DashboardIcon, GroupIcon, LocationIcon, UsersIcon} from "../theme";

export function SideNav() {
	const {t} = useLocalization();
	const tenantId = useTenantId();
	const settingsEdit = useTenantPermission("SETTINGS_EDIT");
	const users = useTenantPermission("TENANT_USER_READ");
	const groups = useTenantPermission("GROUP_READ");
	const audit = useTenantPermission("AUDIT_LOG_READ");
	const dashboard = useTenantPermissions("BOOKING_READ", "TENANT_USER_READ");

	return (
		<List>
			{dashboard && (
				<MainMenuItem
					label={t("dashboard.title")}
					icon={<DashboardIcon />}
					path={`/${tenantId}/dashboard`}
					exact
				/>
			)}
			<MainMenuItem
				label={t("bookings.title")}
				icon={<BookingIcon />}
				path={`/${tenantId}/bookings`}
			/>
			<MainMenuItem
				label={t("compartments.title")}
				icon={<CompartmentIcon />}
				path={`/${tenantId}/compartments`}
			/>
			{users && (
				<MainMenuItem
					label={t("users.title")}
					icon={<UsersIcon />}
					path={`/${tenantId}/users`}
				/>
			)}
			<MainMenuItem
				label={t("locations.title")}
				icon={<LocationIcon />}
				path={`/${tenantId}/locations`}
			/>
			{groups && (
				<MainMenuItem
					label={t("groups.title")}
					icon={<GroupIcon />}
					path={`/${tenantId}/groups`}
				/>
			)}
			{audit && (
				<MainMenuItem
					label={t("audit.title")}
					icon={<AuditIcon />}
					path={`/${tenantId}/audit`}
				/>
			)}
			{settingsEdit && (
				<MainMenuItem
					label={t("settings.title")}
					icon={<Settings />}
					path={`/${tenantId}/settings`}
				/>
			)}
		</List>
	);
}

interface MainMenuItemProps {
	label: string;
	path?: string;
	exact?: boolean;
	icon: ReactElement;
	onClick?: () => void;
}

function MainMenuItem({label, path, exact = false, icon, onClick}: MainMenuItemProps) {
	const match = useMatch({
		path: path ?? "__path_that_cannot_match",
		end: exact,
	});

	const otherProps = path ? {component: RouterLink, to: path} : {onClick};

	return (
		<ListItemButton selected={Boolean(match)} {...otherProps}>
			<ListItemIcon>
				{icon}
			</ListItemIcon>
			<ListItemText primary={label} />
		</ListItemButton>
	);
}
