import {Link} from "@mui/material";
import {DataTable, DataTableCellProps, useDataTableStorage, useSpringPage, useSpringPageable} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {useGroupApi, User} from "../../api";
import {EmptyList} from "../../controls";
import {TFunc, useLocalization} from "../../i18n";
import {useNumericParam} from "../../utils/useParam";
import {UserLinkChip} from "../user/UserChip";

export function GroupUserList() {
	const {t} = useLocalization();
	const groupId = useNumericParam("groupId");
	const {getGroupUsers} = useGroupApi();

	const columns = useMemo(() => createColumns(t), [t]);

	const {onPageChange, onSort, ...storage} = useDataTableStorage("GroupUserList.paging");
	const pageable = useSpringPageable(storage);
	const {loading, error, result} = useAsync(getGroupUsers, [groupId, pageable]);
	const {rows, page} = useSpringPage(result);
	return (
		<DataTable
			columns={columns}
			rows={rows}
			page={page}
			loading={loading}
			error={error}
			onSort={onSort}
			onPageChange={onPageChange}
			empty={<EmptyList />}
			{...storage}
		/>
	);
}

function createColumns(t: TFunc) {
	return [
		{
			label: t("users.displayName"),
			field: "displayName",
			sortable: true,
			default: true,
			component: ({row}: DataTableCellProps<User>) => <UserLinkChip user={row} />,
		},
		{
			label: t("users.username"),
			field: "username",
			sortable: true,
			default: true,
			component: ({row}: DataTableCellProps<User>) => (
				<Link component={RouterLink} to={row.username} color="primary">{row.username}</Link>
			),
		},
		{
			label: t("users.familyName"),
			field: "familyName",
			sortable: true,
			default: true,
		},
		{
			label: t("users.givenName"),
			field: "givenName",
			sortable: true,
			default: true,
		},
		{
			label: t("users.email"),
			field: "email",
			sortable: true,
			default: true,
		},
	];
}
