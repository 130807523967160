import {Alert, AlertTitle} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, ConfirmButton} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {Compartment, CompartmentMutation, useCompartmentApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useLocationPermission} from "../../tenant";
import {useNumericParam} from "../../utils/useParam";
import {View, ViewHeader} from "../View";
import {CompartmentEditForm} from "./CompartmentEditForm";
import {CompartmentLockForm} from "./CompartmentLockForm";

export function CompartmentEdit() {
	const {t} = useLocalization();
	const compartmentId = useNumericParam("compartmentId");
	const navigate = useNavigate();

	const [compartment, setCompartment] = useState<Compartment>();

	const {getCompartment, updateCompartment, assignCompartmentLock, deleteCompartment} = useCompartmentApi();
	const {loading, error, result} = useAsync(getCompartment, [compartmentId]);

	const canDelete = useLocationPermission("COMPARTMENT_DELETE", result?.location.id);

	useEffect(() => {
		setCompartment(result);
	}, [result]);

	async function handleSave(mutation: CompartmentMutation) {
		setCompartment(await updateCompartment(compartmentId, mutation));
	}

	async function assignLock(lockId: string | undefined) {
		setCompartment(await assignCompartmentLock(compartmentId, lockId));
	}

	async function handleDelete() {
		await deleteCompartment(compartmentId);
		navigate(-2); // -1 would be the compartment view, so we do -2
	}

	return (
		<View error={error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={"../.."} relative="path">{t("compartments.title")}</BreadcrumbLink>
				<BreadcrumbLink component={Link} to={".."} relative="path">{compartment?.number}</BreadcrumbLink>
				<BreadcrumbItem>{t("edit")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("compartments.edit.title", compartment)} />
			<CompartmentEditForm loading={loading} compartment={compartment} onSave={handleSave} />
			<CompartmentLockForm loading={loading} lockId={compartment?.lock?.id} onSave={assignLock} />
			{canDelete && (
				<Alert
					severity="error"
					action={
						<ConfirmButton
							cancel={t("cancel")}
							title={t("compartments.delete.title")}
							onConfirm={handleDelete}
							color="error"
						>
							{t("compartments.delete.confirm")}
						</ConfirmButton>
					}
				>
					<AlertTitle>
						{t("compartments.delete.title")}
					</AlertTitle>
					{t("compartments.delete.message")}
				</Alert>
			)}
		</View>
	);
}
