import {DataTable, useDataTableStorage, useSpringPage, useSpringPageable} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {useCompartmentApi} from "../../api";
import {EmptyList} from "../../controls";
import {useLocalization} from "../../i18n";
import {useNumericParam} from "../../utils/useParam";
import {BookingCell} from "../booking/BookingCell";
import {BookingFromCell} from "../booking/BookingFromCell";
import {BookingRetrievalConfirmation} from "../booking/BookingRetrievalConfirmation";
import {BookingUntilCell} from "../booking/BookingUntilCell";
import {BookingUsersCell} from "../booking/BookingUsersCell";

export function CompartmentBookingList() {
	return (
		<BookingRetrievalConfirmation>
			<CompartmentBookingListConfirmed />
		</BookingRetrievalConfirmation>
	);
}

export function CompartmentBookingListConfirmed() {
	const {t} = useLocalization();
	const compartmentId = useNumericParam("compartmentId");

	const {getCompartmentBookings} = useCompartmentApi();

	const columns = useMemo(() => [
		{
			label: t("bookings.single"),
			field: "id",
			sortable: true,
			default: true,
			component: BookingCell,
		},
		{
			label: t("bookings.from"),
			field: "from",
			sortable: true,
			default: true,
			component: BookingFromCell,
		},
		{
			label: t("bookings.until"),
			field: "until",
			sortable: true,
			default: true,
			component: BookingUntilCell,
		},
		{
			label: t("bookings.users.title"),
			field: "user.username",
			sortable: true,
			default: true,
			component: BookingUsersCell,
		},
	], [t]);

	const {onPageChange, onSort, ...storage} = useDataTableStorage("CompartmentBookingList.paging");
	const pageable = useSpringPageable(storage);

	const {loading, error, result} = useAsync(getCompartmentBookings, [compartmentId, pageable]);
	const {rows, page} = useSpringPage(result);

	return (
		<DataTable
			columns={columns}
			rows={rows}
			page={page}
			loading={loading}
			error={error}
			onSort={onSort}
			onPageChange={onPageChange}
			empty={<EmptyList />}
			{...storage}
		/>
	);
}
