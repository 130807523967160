import {Button, Chip, Stack} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, Tabs} from "@variocube/app-ui";
import React, {Fragment} from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {useCompartmentApi} from "../../api";
import {DisabledChip} from "../../controls/DisabledChip";
import {useLocalization} from "../../i18n";
import {useLocationPermission, useTenantId, useTenantPermission} from "../../tenant";
import {useSearchParam} from "../../utils/search";
import {useNumericParam} from "../../utils/useParam";
import {LocationLinkChip} from "../location/LocationChip";
import {View, ViewHeader} from "../View";
import {CompartmentAuditTimeline} from "./CompartmentAuditTimeline";
import {CompartmentBookingList} from "./CompartmentBookingList";
import {CompartmentStatusChip} from "./CompartmentStatusChip";
import {LockChip} from "./LockChip";
import {LockStatusChip} from "./LockStatusChip";

export function CompartmentView() {
	const {t} = useLocalization();
	const tenantId = useTenantId();
	const compartmentId = useNumericParam("compartmentId");
	const [tab, setTab] = useSearchParam("tab", "bookings");

	const {getCompartment} = useCompartmentApi();

	const {loading, error, result: compartment} = useAsync(getCompartment, [compartmentId]);

	const canEdit = useLocationPermission("COMPARTMENT_EDIT", compartment?.location.id);
	const canCreateBooking = useLocationPermission("BOOKING_CREATE", compartment?.location.id);
	const canCreateOwnBooking = useLocationPermission("BOOKING_CREATE_OWN", compartment?.location.id);
	const canReadBookings = useTenantPermission("BOOKING_READ");
	const canReadAudit = useTenantPermission("AUDIT_LOG_READ");

	const tabItems = [
		...canReadBookings ? [{label: t("bookings.title"), value: "bookings"}] : [],
		...canReadAudit ? [{label: t("audit.title"), value: "audit"}] : [],
	];

	return (
		<View loading={loading} error={error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={".."}>{t("compartments.title")}</BreadcrumbLink>
				<BreadcrumbItem>{compartment?.number}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={t("compartments.view.title", compartment)}
				subTitle={
					<Stack direction="row" spacing={2}>
						{compartment && <LocationLinkChip location={compartment.location} />}
						{compartment?.size && <Chip label={compartment.size} />}
						{compartment?.lock?.id && <LockChip lock={compartment.lock.id} />}
					</Stack>
				}
				titleAdornment={
					<Stack direction="row" spacing={2}>
						{compartment?.lock?.status && <LockStatusChip status={compartment.lock.status} />}
						{compartment && !compartment.enabled && <DisabledChip />}
						{compartment && <CompartmentStatusChip booked={compartment.booked} />}
					</Stack>
				}
				actions={
					<Stack direction="row" spacing={2}>
						{(canCreateBooking || canCreateOwnBooking) && (
							<Button
								variant="contained"
								color="primary"
								disabled={!compartment || !compartment.bookable}
								component={Link}
								to={`/${tenantId}/bookings/new?compartment=${compartment?.id}`}
							>
								{t("bookings.create.title")}
							</Button>
						)}
						{canEdit && (
							<Button variant="outlined" color="primary" component={Link} to="edit">
								{t("edit")}
							</Button>
						)}
					</Stack>
				}
			/>
			{tabItems.length > 0 && (
				<Fragment>
					<Tabs
						value={tab}
						onChange={(_, tab) => setTab(tab)}
						items={tabItems}
					/>
					{canReadBookings && tab == "bookings" && <CompartmentBookingList />}
					{canReadAudit && tab == "audit" && <CompartmentAuditTimeline />}
				</Fragment>
			)}
		</View>
	);
}
