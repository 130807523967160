import {Box, useTheme} from "@mui/material";
import React, {PropsWithChildren} from "react";

export interface BoxNumberProps {
	color?: "secondary";
}

export function BoxNumber(props: PropsWithChildren<BoxNumberProps>) {
	const theme = useTheme();
	const {color = "secondary", children} = props;

	return (
		<Box
			sx={{
				bgcolor: theme.palette[color].main,
				color: theme.palette[color].contrastText,
				px: 1.5,
				py: 0.5,
				borderRadius: 1.5,
			}}
		>
			{children}
		</Box>
	);
}
